import moment from 'moment';
import i18n from '@/locale';
import chartOptions from '../chart/chart_options';
import request from '../utils';
import helper from '../helper';
import setDate from '../component/date';
import icon from '../component/common';

const get_SantralLiveData = (formdata, measureList, url) => new Promise((resolve) => {
  const url1 = '/panel/sensorbox/santralList';

  const santralData = request.Api(url, formdata.santral, 'post');
  const sensorboxData = request.Api(url1, formdata.sensorbox, 'post');

  Promise.all([santralData, sensorboxData]).then((values) => {
    let liveData = {
      Irr: 0,
      Temp: 0,
      IstantaneousPower: 0,
      PerformanceRatio: 0,
    };
    if (values[0].data.success == 'true' && values[0].data !== undefined) {
      liveData = {
        ...liveData,
        ...values[0].data.data,
      };
    }
    if (values[1].data.success == 'true' && values[1].data.data !== null) {
      let Irr = 0;
      let Temp = 0;

      let indexirr = 0;
      let indextemp = 0;
      Object.keys(values[1].data.data).forEach((item) => {
        Object.keys(values[1].data.data[item]).forEach((i) => {
          if (i.indexOf('Irr') > -1) {
            const tmp = values[1].data.data[item][i];
            if (tmp > 0) {
              Irr += tmp;
              indexirr++;
            }
          }
          if (i.indexOf('Temp') > -1) {
            const tmp = values[1].data.data[item][i];

            if (tmp > 0) {
              Temp += tmp;
              indextemp++;
            }
          }
        });
      });

      if (indexirr == 0) {
        indexirr = 1;
      }
      if (indextemp == 0) {
        indextemp = 1;
      }
      const sensorObj = {
        Irr: 0,
        Temp: 0,
      };
      sensorObj.Irr = Irr / indexirr;
      sensorObj.Temp = Temp / indextemp;

      liveData = {
        ...liveData,
        ...sensorObj,
      };
    }

    let chartOption = {

    };
    if (values[0].data.data) {
      chartOption = {
        updateTime: values[0].data.data.date,
      };
    }

    let icons = '';
    measureList.forEach((item) => {
      icons = icon.measures_icon(item.value);
      const max = icon.measures_max(item.value);

      const tmpData = [{
        value: parseFloat(liveData[item.value]).toFixed(2),
        name: item.text,
        title: {
          offsetCenter: [0, '95%'],
        },
        detail: {
          offsetCenter: [0, '80%'],
        },
      }];

      const option = chartOptions.gauge_options(tmpData, max, icons);
      chartOption[item.value] = option;
    });

    resolve(chartOption);
  });
});

const getSantralLiveData = (formdata, measureList) => new Promise((resolve) => {
  const url = '/panel/santral/lastpower';
  const url1 = '/panel/sensorbox/list';

  const santralData = request.Api(url, formdata.santral, 'post');
  const sensorboxData = request.Api(url1, formdata.sensor, 'post');

  Promise.all([santralData, sensorboxData]).then((values) => {
    let liveData = {
      Irr: 0,
      Temp: 0,
      IstantaneousPower: 0,
      PerformanceRatio: 0,
    };

    if (values[0].data.success == 'true' && values[0].data !== undefined) {
      liveData = {
        ...liveData,
        ...values[0].data.data[0],
      };
    }
    if (values[1].data.success == 'true' && values[1].data.data !== null) {
      const tempVal = {
        Irr: 0,
        Temp: 0,
      };

      let irrindex1 = 0;
      let tempindex1 = 0;
      const {
        sensor
      } = formdata.sensor.condiniton;

      values[1].data.data.forEach((item) => {
        const irr_key = sensor[item.SENSOR].Irr.value;
        const irr_value = sensor[item.SENSOR].Irr.value;

        const temp_key = sensor[item.SENSOR].Temp.value;
        const temp_value = sensor[item.SENSOR].Temp.value;

        if (item[irr_key] > 0) {
          tempVal[irr_value] += item[irr_key];
          irrindex1++;
        }

        if (item[temp_key] > 0) {
          tempVal[temp_value] += item[temp_key];
          tempindex1++;
        }
      });

      if (tempVal.Irr > 0) {
        tempVal.Irr = parseInt(tempVal.Irr / irrindex1);
      }
      if (tempVal.Temp > 0) {
        tempVal.Temp = parseInt(tempVal.Temp / tempindex1);
      }

      liveData = {
        ...liveData,
        ...tempVal,
      };
    }

    const chartOption = {};
    if (values[0].data.success == 'true') {
      if (values[0].data.data[0]) {
        chartOption.updateTime = values[0].data.data[0].date;
      }
    }

    measureList.forEach((item) => {
      const max = icon.measures_max(item.value);
      const tmpData = [{
        value: parseFloat(liveData[item.value]).toFixed(2),
        name: i18n.t(item.value),
        title: {
          offsetCenter: [0, '70%'],
        },
        detail: {
          offsetCenter: [0, '90%'],
        },
      }];

      const option = chartOptions.gauge_options(tmpData, max, item.unit);
      chartOption[item.value] = option;
    });

    resolve(chartOption);
  });
});

const getSantralTotalTableData = (measures, selectedsantral) => new Promise((resolve) => {
  let s_url = 'santraller';
  if (selectedsantral != 'null' && selectedsantral !== undefined) {
    s_url = 'santral';
  }

  // ----------daily--------------------------------
  const promise = [];
  const startDate = moment().format('YYYY-MM-DD');

  const d_url = `/panel/${s_url}/dailypower`;

  const d_formdata = {
    condiniton: {
      measures,
      startDate,
      finishDate: startDate,
    },
  };
  if (selectedsantral != 'null') {
    d_formdata.condiniton.santral = selectedsantral;
  }

  const dailyData = request.Api(d_url, d_formdata, 'post');
  promise.push(dailyData);

  // ----------month--------------------------------
  const year = moment().format('YYYY');
  const month = parseInt(moment().format('MM'));

  const m_formdata = {
    condiniton: {
      measures,
      date: [{
        year,
        month: [month],
      }],
    },
  };
  if (selectedsantral != 'null') {
    m_formdata.condiniton.santral = selectedsantral;
  }

  const url1 = `/panel/${s_url}/monthlypower`;

  const monthData = request.Api(url1, m_formdata, 'post');
  promise.push(monthData);

  // ----------year--------------------------------

  const url2 = `/panel/${s_url}/yearlypower`;

  const y_formdata = {
    condiniton: {
      measures,
      date: [year],
    },
  };
  if (selectedsantral != 'null') {
    y_formdata.condiniton.santral = selectedsantral;
  }
  const yearData = request.Api(url2, y_formdata, 'post');
  promise.push(yearData);

  // ----------total--------------------------------

  const url3 = `/panel/${s_url}/totalpower`;

  const t_formdata = {
    condiniton: {
      measures,
    },
  };
  if (selectedsantral != 'null') {
    t_formdata.condiniton.santral = selectedsantral;
  }
  const totalData = request.Api(url3, t_formdata, 'post');
  promise.push(totalData);

  Promise.all(promise).then((values) => {
    const tmpData = [];

    if (values[0].data.success == 'true') {
      let tmp;
      if (Array.isArray(values[0].data.data)) {
        tmp = values[0].data.data[0];
      } else {
        tmp = values[0].data.data;
      }

      const obj = setMeasures(tmp);
      obj.date = moment().format('DD-MM-YYYY');
      tmpData.push(obj);
    } else {
      const obj = {};
      measures.forEach((measure) => {
        obj[measure] = 0;
      });
      obj.date = moment().format('DD-MM-YYYY');
      tmpData.push(obj);
    }

    if (values[1].data.success == 'true') {
      let tmp;
      if (Array.isArray(values[1].data.data)) {
        tmp = values[1].data.data[0];
      } else {
        tmp = values[1].data.data;
      }

      const m = parseInt(moment().format('MM'));

      const obj = setMeasures(tmp);
      obj.date = `${moment().format('YYYY')} ${setDate.monthLabel(m - 1)}`;
      tmpData.push(obj);
    } else {
      const obj = {};
      measures.forEach((measure) => {
        obj[measure] = 0;
      });
      const m = parseInt(moment().format('MM'));

      obj.date = `${moment().format('YYYY')} ${setDate.monthLabel(m - 1)}`;
      tmpData.push(obj);
    }

    if (values[2].data.success == 'true') {
      let tmp;
      if (Array.isArray(values[2].data.data)) {
        tmp = values[2].data.data[0];
      } else {
        tmp = values[2].data.data;
      }

      const obj = setMeasures(tmp);
      obj.date = moment().format('YYYY');
      tmpData.push(obj);
    } else {
      const obj = {};
      measures.forEach((measure) => {
        obj[measure] = 0;
      });

      obj.date = moment().format('YYYY');
      tmpData.push(obj);
    }
    if (values[3].data.success == 'true') {
      let tmp;
      if (Array.isArray(values[3].data.data)) {
        tmp = values[3].data.data[0];
      } else {
        tmp = values[3].data.data;
      }

      const obj = {
        date: 'Toplam',
        DailyEnergy: (parseFloat(tmp.TotalEnergy) / 1000).toFixed(2),
        PerformanceRatio: '',
        TheoreticalPower: '',
        // Price: (parseFloat(tmp.TotalEnergy) * 0.133 * 1.18).toFixed(0),
      };

      tmpData.push(obj);
    }

    resolve(tmpData);
  });
});
const setMeasures = (data) => {
  Object.keys(data).forEach((measure) => {
    if (measure == 'BarrelsofOil') {
      data[measure] = (parseFloat(data[measure])).toFixed(0);
    }
    if (measure == 'CO2Avoided') {
      data[measure] = (parseFloat(data[measure])).toFixed(0);
    }
    if (measure == 'EquivalentTrees') {
      data[measure] = (parseFloat(data[measure])).toFixed(0);
    }

    if (measure == 'PerformanceRatio') {
      data[measure] = (parseFloat(data[measure])).toFixed(0);
    }
    if (measure == 'TheoreticalPower') {
      data[measure] = (parseFloat(data[measure] / 1000)).toFixed(2);
    }

    if (measure == 'DailyEnergy') {
      const daily = parseFloat(data[measure]);
      data[measure] = (parseFloat(daily / 1000)).toFixed(2);
      data.Price = (parseFloat(daily) / 1000 * 0.133 * 1.18 * 1000).toFixed(0);
    }
  });

  return data;
};

const getSantralMinuteData_Dash = (formdata, measureList, text, chartTypeModel) => new Promise((
  resolve) => {
  const url = '/panel/santraller/minuteGrup';

  const tempData = request.Api(url, formdata, 'post');
  tempData.then((_rawData) => {
    if (_rawData.status !== 200) {
      resolve({});
      return;
    }
    if (_rawData.data.success === 'false') {
      resolve({});
      return;
    }
    const sortType = 'Hour';

    const colors = ['#5470C6', '#91CC75', '#EE6666'];
    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const series = [];
    const measures = [];
    const timeField = [];
    const yAxis = [];
    const max_val = {};

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let mm;
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;

      tempTimeData[date] = item;

      measureList.forEach((measure) => {
        const tmp1 = parseInt(item[measure.value]);

        if (!max_val[measure.value]) {
          max_val[measure.value] = 0;
        }

        if (tmp1 > max_val[measure.value]) {
          max_val[measure.value] = tmp1;
        }
      });
    });

    let colorKey = 0;
    let offset = 0;
    let total_Val = 0;

    const step_time = helper.getUserStep();

    measureList.forEach((measure) => {
      measures.push(`${measure.value} ${measure.unit}`);
      const tempData = [];
      const minute = 0;
      const hour = 5;
      const vals = 0;

      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += step_time) {
          let h;
          let
            m;
          i < 10 ? h = `0${i}` : h = `${i}`;
          x < 10 ? m = `0${x}` : m = `${x}`;

          const time = `${h}:${m}`;

          if (colorKey == 0) {
            timeField.push(time);
          }

          if (tempTimeData[time]) {
            tempData.push(Math.round(tempTimeData[time][measure.value], 1));
          } else {
            tempData.push(0);
          }
        }
      }


      let chartType = chartTypeModel;
      if (chartTypeModel == 'line2') {
        chartType = 'line';
      }

      const obj = {
        name: `${i18n.t(measure.value)}(${measure.unit})`,
        data: tempData,
        type: chartType,
      };

      let position = 'left';
      if (colorKey > 0) {
        position = 'right';
        obj.yAxisIndex = colorKey;
      }

      if (colorKey > 1) {
        offset += 110;
      }
      if (chartTypeModel == 'line2') {
        obj.areaStyle = {};
      }

      total_Val = max_val[measure.value] * 120 / 100;

      const x_Axis = {
        type: 'value',
        name: `${i18n.t(measure.value)}(${measure.unit})`,
        min: 0,
        max: total_Val,
        offset,
        position,
        axisLine: {
          show: true,
          lineStyle: {
            color: colors[colorKey],
          },
        },
        axisLabel: {
          formatter: `{value} ${measure.unit}`,
        },
      };
      if (colorKey > 1) {
        x_Axis.show = false;
      }

      yAxis.push(x_Axis);
      series.push(obj);
      colorKey += 1;
    });

    const options = chartOptions.line_options_multies(text, measures, timeField, series,
      colors, yAxis);

    resolve(options);
  });
});


const get_SantralListLiveData = (formdata, self, url, measureList) => new Promise((resolve) => {
  const santralData = request.Api(url, formdata.santral, 'post');
  const santrals = self.$store.getters.santralRawData;

  Promise.all([santralData, santrals]).then((values) => {
    const liveData = [];

    const alarm = {
      connection: 0,
      error: 0,
      warning: 0,
    };

    Object.keys(values[0].data.data).forEach((key) => {
      const obj = {
        santral: values[0].data.data[key].santral,
        date: values[0].data.data[key].date,
        error: values[0].data.data[key].error,
        warning: values[0].data.data[key].warning,
        connection: values[0].data.data[key].connection,
      };

      alarm.error += values[0].data.data[key].error;
      alarm.warning += values[0].data.data[key].warning;
      alarm.connection += values[0].data.data[key].connection;

      measureList.forEach((measure) => {
        let value = 0;

        const vals = values[0].data.data[key][measure];
        if (typeof vals === 'object' && vals !== undefined && vals !== null) {
          value = vals.value;
        } else {
          value = (parseFloat(vals)).toFixed(2);
        }

        obj[measure] = value;
      });
      liveData.push(obj);
    });

    resolve({
      alarm,
      liveData,
    });
  });
});

export default {
  getSantralLiveData,
  get_SantralLiveData,
  getSantralTotalTableData,
  getSantralMinuteData_Dash,
  get_SantralListLiveData,
};
