<template>
  <div>
    <v-container class="container--fluid">
      <v-row class="mt-0 pt-0 mb-0 pb-0">
        <v-spacer />
        <v-col
          class="mt-0 pt-0 mb-0 pb-0"
          cols="3"
          sm="12"
          md="3"
        >
          <Weather />
        </v-col>

        <v-col
          cols="9"
          sm="9"
          md="9"
          class="mt-0 pt-0 mb-0 pb-0"
        >
          <InstantDataGauge />

          <v-col
            class="d-flex ml-0 mr-0 pl-0 pr-0"
            cols="12"
            sm="12"
            md="12"
          >
            <SantralStats />
            <SantralInfo />
          </v-col>
        </v-col>
      </v-row>

      <v-row dense>
        <DailyMinuteData />
      </v-row>

      <v-row dense>
        <SantralList />
      </v-row>
    </v-container>
  </div>
</template>

<script>

import InstantDataGauge from '@/views/components/Dashboard/InstantDataGauge.vue';
import SantralList from '@/views/components/Dashboard/SantralList.vue';
import DailyMinuteData from '@/views/components/Dashboard/DailyMinuteData.vue';
import SantralStats from '@/views/components/Dashboard/SantralStats.vue';
import SantralInfo from '@/views/components/Dashboard/SantralInfo.vue';
import Weather from '@/views/components/Dashboard/Weather.vue';

export default {
  name: 'Dashboard',
  components: {
    InstantDataGauge,
    SantralList,
    DailyMinuteData,
    SantralStats,
    SantralInfo,
    Weather,
  },
  data: () => ({
    santralmodel: 'null',
  }),
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
