<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import echarts from "echarts";
// import debounce from "@/utils";
import resize from "./resize";

export default {
  name: "Chart",
  mixins: [resize],
  props: {
    id: {
      type: String,
      default: "chart",
    },
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "900px",
    },
    height: {
      type: String,
      default: "300px",
    },
    chartData: {},
    chartriseze: {},
    resize: {},
  },
  data: () => ({
    chart: null,
  }),
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        if (Object.keys(val).length > 0) {
          this.setOptions(val);
          this.chart.resize();
        }
      },
    },

    /*  $route: function (from, to) {
      
      setTimeout(() => {
        if (this.chart) {
         // this.chart.resize();
        }
      }, 5);
    }, */
  },
  mounted() {
    setTimeout(() => {
      this.initChart();
    }, 200);
  },

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    resize_Handler() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    setOptions(option = {}) {
      if (this.chart !== undefined && this.chart !== null) {
        this.chart.setOption(option, true);
      }
    },

    initChart() {
      this.chart = echarts.init(document.getElementById(this.id));
      this.setOptions(this.chartData);
    },
  },
};
</script>
