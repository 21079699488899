<template>
  <v-col cols="12">
    <v-card>
      <v-col cols="12" class="pl-0 pr-0">
        <v-row>
          <v-spacer />

          <v-col cols="3" sm="2" md="2" class="pr-3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="startDate" no-title scrollable>
                <v-spacer />
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(startDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1" sm="1" md="1" class="pr-3">
            <v-select
              v-model="chartTypeModel"
              :items="chartType"
              label="Grafik"
            />
          </v-col>
          <v-col cols="3" sm="2" md="2" class="pr-10 pt-5">
            <v-btn color="primary" @click="getSantralDataChart">
              Filtrele
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <echart
          id="general-santral-power-daily-dash"
          class-name="widget-content"
          height="400px"
          min-width="900px"
          width="100%"
          :chart-data="chartData"
         
        />
      </v-col>
    </v-card>
  </v-col>
</template>

<script>
import Echart from "@/views/components/Echart/Chart.vue";
import * as Santral from "@/api/Dashboard/Santral";
import moment from "moment";
import i18n from "@/locale";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    componentKey: 1,
    chartData: {},
    chartTypeModel: "line2",
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    chartType: ["line", "bar", "line2"],
    menu: false,
    modal: false,
    menu2: false,
    riseze: "false",
    measures: [],
  }),
  computed: {},
  watch: {
    $route: function (from, to) {
      this.riseze = "true";
    },
  },
  created() {},
  mounted() {
    const self = this;
    const myVar2 = setInterval(myTimer2, 1000);

    function myTimer2() {
      const StatsMeasure = self.$store.getters.statsmeasureList;
      if (StatsMeasure.length > 0 && StatsMeasure !== undefined) {
        clearInterval(myVar2);
        self.getSantralDataChart();
      }
    }

    setInterval(this.getSantralDataChart, 300000);
  },

  methods: {
    getSantralDataChart() {
      this.finishDate = moment(this.startDate)
        .add(1, "day")
        .format("YYYY-MM-DD");

      const measures = [];
      const measureList = [];
      const measure_List = [];
      const tempmeasure_List = this.$store.getters.statsmeasureList;
      const tempmeasureList = [
        {
          value: "IstantaneousPower",
          text: i18n.t("IstantaneousPower"),
          type: "float",
          unit: "Kw",
        },
        {
          value: "PerformanceRatio",
          text: i18n.t("PerformanceRatio"),
          type: "float",
          unit: "%",
        },

        {
          value: "TheoreticalPower",
          text: i18n.t("TheoreticalPower"),
          type: "float",
          unit: "kW",
        },
      ];

      const energy = ["DailyEnergy", "TotalEnergy"];

      tempmeasureList.forEach((item) => {
        if (energy.indexOf(item.value) < 0) {
          measures.push(item.value);
          measureList.push(item);
        }
      });

      tempmeasure_List.forEach((item) => {
        if (energy.indexOf(item.value) < 0) {
          measure_List.push(item.value);
        }
      });

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          measures: measure_List,
        },
      };
      console.log(params)

      const chartText = "";
      const self = this;

      const tempData = Santral.default.getSantralMinuteData_Dash(
        params,
        measureList,
        chartText,
        self.chartTypeModel
      );
      tempData.then((res) => {
        self.chartData = res;
        self.componentKey += 1;
        self.forceRerender();
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
