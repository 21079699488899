import request from '../utils';

const getError = (formdata) => new Promise((resolve) => {
  const url = '/panel/notification/error';
  const tempData = request.Api(url, formdata, 'post');
  tempData.then((_rawData) => {
    resolve({
      data: _rawData.data.data,
      count: _rawData.data.totalRecordCount,
    });
  });
});

const getErrorTime = (formdata) => new Promise((resolve) => {
  const url = '/panel/notification/device';
  const tempData = request.Api(url, formdata, 'post');
  tempData.then((_rawData) => {
    resolve({
      data: _rawData.data.data,
      count: _rawData.data.totalRecordCount,
    });
  });
});
export default {
  getError,
  getErrorTime,
};
