<template>
  <v-col
    cols="12"
    sm="9"
    md="9"
    class="mt-0 pt-0 mb-0 pb-0 ml-0 mr-0 pl-0 pr-0"
  >
    <v-card class="mt-0 pt-0 mb-0 pb-0 ml-0 mr-0 pl-0 pr-0">
      <v-data-table
        dense
        :headers="production_header"
        :items="items"
        hide-default-footer
        class="elevation-1"
      />
    </v-card>
  </v-col>
</template>

<script>
import * as Santral from '@/api/Dashboard/Santral';
import i18n from '@/locale';

export default {
  data: () => ({
    items: [],
  }),
  computed: {
    production_header() {
      return [
        {
          text: i18n.t('general.date'), value: 'date', width: '100', sortable: false,
        },
        {
          text: `${i18n.t('TotalEnergy')}(MW)`,
          value: 'DailyEnergy',
          sortable: false,
        },
        {
          text: `${i18n.t('TheoreticalPower')}(MW)`,
          value: 'TheoreticalPower',
          sortable: false,
        },
        {
          text: `${i18n.t('PerformanceRatio')}(%)`,
          value: 'PerformanceRatio',
          sortable: false,
        },
        {
          text: i18n.t('EquivalentTrees'),
          value: 'EquivalentTrees',
          sortable: false,
        },
        {
          text: i18n.t('CO2Avoided'),
          value: 'CO2Avoided',
          sortable: false,
        },
        {
          text: i18n.t('BarrelsofOil'),
          value: 'BarrelsofOil',
          sortable: false,
        },
      ];
    },
  },
  created() {},
  mounted() {
    const myVar2 = setInterval(myTimer2, 1000);

    const self = this;
    function myTimer2() {
      const StatsMeasure = self.$store.getters.statsmeasureList;
      if (StatsMeasure.length > 0 && StatsMeasure !== undefined) {
        clearInterval(myVar2);
        self.getTableData();
      }
    }
    setInterval(this.getTableData, 300000);
  },
  methods: {
    getTableData() {
      const tempmeasure_List = this.$store.getters.statsmeasureList;
      const measureList = [];

      const measures = ['CO2Avoided', 'BarrelsofOil', 'EquivalentTrees', 'Price'];
      tempmeasure_List.forEach((item) => {
        measures.push(item.value);
        measureList.push(item);
      });

      const tempData = Santral.default.getSantralTotalTableData(measures);
      tempData.then((res) => {
        this.items = res;
      });
    },
  },
};
</script>

<style scoped>
</style>
