<template>
  <v-col cols="12" sm="3" md="3" class="mt-0 pt-0 mb-0 pb-0">
    <v-card v-if="componentKey" class="mt-0 pt-0 mb-0 pb-0" max-width="400">
      <v-data-table
        dense
        :headers="santral_headers"
        :items="santral_items"
        hide-default-header
        hide-default-footer
        class="elevation-1"
      />
    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    santral_items: [],
    componentKey: 1,
  }),
  computed: {
    santral_headers() {
      return [
        { text: "type", value: "type" },
        { text: "value", value: "value" },
      ];
    },
  },
  created() {
    this.componentKey += 1;
  },
  mounted() {
    const self = this;

    const myVarİnv = setInterval(myTimerİnv, 1000);
    const k = 0;
    function myTimerİnv() {
      const { inverterList } = self.$store.getters;

      if (k > 30) {
        clearInterval(myVarİnv);
      }

      let inverterQuantity = 0;
      if (Object.keys(inverterList).length > 0) {
        clearInterval(myVarİnv);

        Object.keys(inverterList).forEach((item) => {
          inverterQuantity += inverterList[item].length;
        });
        self.santral_items = [
          {
            type: "İnverter Sayısı",
            value: 0,
          },
          {
            type: "Strings Sayısı",
            value: 0,
          },
          {
            type: "Modül Sayısı",
            value: 0,
          },
          {
            type: "Sensor Sayısı",
            value: 0,
          },
          {
            type: "Güncelleme zamanı",
            value: moment().format("YYYY-MM-DD HH:mm:s"),
          },
        ];

        self.santral_items[0].value = inverterQuantity;
      }
    }

    const myVarİnvMod = setInterval(myTimerİnvMod, 1000);
    function myTimerİnvMod() {
      const { inverterInfo } = self.$store.getters;
      let inverterModuleQuantity = 0;
      if (Object.keys(inverterInfo).length > 0) {
        clearInterval(myVarİnvMod);
        Object.keys(inverterInfo).forEach((item) => {
          Object.keys(inverterInfo[item]).forEach((i) => {
            if (inverterInfo[item][i].hasOwnProperty("ModuleQuantity")) {
              inverterModuleQuantity += inverterInfo[item][i].ModuleQuantity;
            }
          });
        });
        self.santral_items[2].value = inverterModuleQuantity;
      }
    }

    const myVarString = setInterval(myTimerString, 1000);
    function myTimerString() {
      const { stringboxInfo } = self.$store.getters;
      let stringsQuantity = 0;
      if (Object.keys(stringboxInfo).length > 0) {
        clearInterval(myVarString);
        Object.keys(stringboxInfo).forEach((item) => {
          Object.keys(stringboxInfo[item]).forEach((i) => {
            if (stringboxInfo[item][i].hasOwnProperty("stringQuantity")) {
              stringsQuantity += stringboxInfo[item][i].stringQuantity;
            }
          });
        });
        self.santral_items[1].value = stringsQuantity;
      }
    }

    const myVarSensor = setInterval(myTimerSensor, 1000);

    function myTimerSensor() {
      const { sensorList } = self.$store.getters;

      let sensorQuantity = 0;
      if (Object.keys(sensorList).length > 0) {
        clearInterval(myVarSensor);
        Object.keys(sensorList).forEach((item) => {
          sensorQuantity += sensorList[item].length;
        });
        self.santral_items[3].value = sensorQuantity;
      }
    }

    setInterval(this.santralgeneralupdate, 300000);

    setTimeout(() => {
      self.santralgeneralupdate();
    }, 2000);
  },
  methods: {
    santralgeneralupdate() {
      if (this.santral_items.length > 0) {
        this.santral_items[4].value = this.$store.getters.santralgeneralupdate;
      }
    },
  },
};
</script>

<style scoped>
</style>
