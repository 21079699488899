<template>
  <div>
    <v-row class="mr-3 mt-1">
      <v-col
        v-for="(measure, key) in measureList"
        :key="key"
        cols="3"
        sm="3"
        md="3"
        class="pr-0 mt-0 pt-0 m-0"
      >
        <v-card>
          <echart
            :id="measure.value + 'general-santral-live-data'"
            class-name="widget-content mr-2 ml-2"
            height="190px"
            width="95%"
            :chart-data="chartData[measure.value]"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Echart from '@/views/components/Echart/Chart.vue';
import * as Santral from '@/api/Dashboard/Santral';
import i18n from '@/locale';
import moment from 'moment';
import helper from '@/api/helper';

export default {
  components: {
    Echart,
  },
  data: () => ({
    chartData: {},
    riseze: "false",
  }),
  computed: {
    measureList() {
      return [
        {
          value: 'PerformanceRatio',
          text: i18n.t('PerformanceRatio'),
          type: 'float',
          unit: '%',
        },
        {
          value: 'IstantaneousPower',
          text: i18n.t('IstantaneousPower'),
          type: 'float',
          unit: 'Kw',
        },
        { value: 'Irr', text: i18n.t('Irradiation') },
        { value: 'Temp', text: i18n.t('Temperature') },
      ];
    },
  },
 watch: {
    $route: function (from, to) {
      this.riseze = "true";
    },
  },
  created() {},
  mounted() {
   
    let { stepTime } = this.$store.getters;
    if (stepTime !== undefined || stepTime !== null) {
      stepTime = 4;
    }

    if (stepTime < 4) {
      stepTime = 4;
    }
    setTimeout(() => {
      this.getSantralLiveData();
    }, 1500);

    setInterval(this.getSantralLiveData, 300000);
  },

  methods: {
    async getSantralLiveData() {
      const formdata = {};

      const measures = [];
      const generalStatsMeasureList = [
        {
          value: 'PerformanceRatio',
          text: i18n.t('PerformanceRatio'),
          type: 'float',
          unit: '%',
        },
        {
          value: 'IstantaneousPower',
          text: i18n.t('IstantaneousPower'),
          type: 'float',
          unit: 'Kw',
        },
      ];

      generalStatsMeasureList.forEach((item) => {
        measures.push(item.value);
      });
      // let date = "2021-08-06";
      const date = moment().format('YYYY-MM-DD');

      formdata.santral = {
        condiniton: {
          skip: 0,
          limit: 1,
          orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
          count: '0',
          totalVal: '1',
          date,
          measures,
        },
      };

      const sensor = await this.$store.getters.sensorInfo;

      if (Object.keys(sensor).length > 0) {
        const sensorList = {};
        Object.keys(sensor).forEach((item) => {
          const sns = {};
          Object.keys(sensor[item]).forEach((i) => {
            sns[sensor[item][i].SENSOR] = sensor[item][i];
          });

          sensorList[item] = sns;
        });
        formdata.sensorbox = {
          condiniton: {
            skip: 0,
            limit: 1,
            orderBys: [{ fieldName: 'datetimestamp', sortOrder: 'desc' }],
            count: '0',
            totalVal: '1',
            date,
            sensor: sensorList,
          },
        };
      }

      const url = '/panel/santraller/lastpower';
 
      const minuteData = Santral.default.get_SantralLiveData(
        formdata,
        this.measureList,
        url,
      );
      
      minuteData.then((res) => {
       
        const santrals = this.$store.getters.santralRawData;

        if (santrals !== undefined) {
          res.IstantaneousPower.series[0].max = 1000 * santrals.length;
        }

        this.chartData = res;
        this.$store.dispatch('setGeneralUpdateTime', res.updateTime);
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style>
.v-card__subtitle {
  margin-bottom: -15px !important;
}
</style>
